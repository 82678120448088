.dialog-overlay {
  width: 100%;
  height: 100%;
  max-height: 475px;
  min-height: 100%;
  position: relative;
  background-color: #fff;
  overflow-y: auto;
  flex: 1;
}

.dialog-bubble {
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 5px;
  margin-bottom: 10px;
  line-height: normal;
}
.dialog-bubble:first-child {
  margin-top: 10px;
}

.dialog-bubble.ai-bubble {
  border-color: orange;
  color: black;
}

.dialog-bubble.stt-bubble {
  border-color: teal;
  color: black;
}

.dialog-header {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.dialog-text {
  font-size: 16px;
}
.intervention-notice {
  background-color: red;
  color: white;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 10px;
  line-height: 1;
}
.latency-text {
  font-style: italic;
}