.order-summary {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background-color: #fff;
  flex: 1;
  width: 100%;
  height: 100%;
  max-height: 475px;
  min-height: 100%;
  position: relative;
  overflow-y: auto;
}

.order-item {
  border-bottom: 1px solid #ccc;
  padding: 10px;
}
.order-item:first-child {
  margin-top: 10px;
}

.order-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-item-quantity {
  font-weight: bold;
}

.order-item-name {
  font-weight: bold;
  flex: 1;
  margin: 0 10px;
}

.order-item-price {
  font-weight: bold;
}

.order-item-modifiers {
  margin: 10px 0 0 20px; /* Add indentation for the list */
  padding: 0;
  list-style-type: disc; /* Bullet points */
  color: #555;
  padding-left: 15px;
}

.order-item-modifiers {
  list-style: none;
  padding: 0;
  margin: 0;
}

.order-item-modifier {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #eee; /* Optional, for separation */
}

.modifier-name {
  flex-grow: 1; /* Ensures the name takes up available space */
  padding-left: 15px;
}

.modifier-price {
  text-align: right;
  white-space: nowrap; /* Prevents wrapping if the name is long */
}
.order-summary-total {
  text-align: end;
  margin-top: 5px;
}