/* Container styling */
.toggle-container {
  width: 140px;
  display: flex;
  justify-content: center;
}

/* Toggle switch container */
.toggle-switch {
  width: 50px;
  height: 25px;
  background-color: #e0e0e0;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

/* Toggle switch active states */
.toggle-switch.staging {
  background-color: #FF6B6B;
}

.toggle-switch.production {
  background-color: #4caf50;
}

/* Circle inside the toggle */
.toggle-circle {
  width: 19px;
  height: 19px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

/* Position circle based on the environment */
.toggle-switch.production .toggle-circle {
  transform: translateX(25px);
}

/* Optional text styling */
p {
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
}
.toggle-text-switch {
  display: flex;
  justify-content: end;
  width: 100%;
  flex-direction: column;
  align-items: end;
  margin-right: 50px;

  position: fixed;
  bottom: 70px;
}
.phone-number-toggle {
  bottom: 150px
}