/* Body styling */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Scene container to hold the menu board and ground */
.scene {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
}

/* Menu board container */
.menu-board {
  position: absolute;
  bottom: 25vh;
  width: 95%;
  max-width: 1600px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  background-color: #333;
  padding: 20px;
  transform: scale(0.85);
  transform-origin: center bottom;
}

/* Individual menu sections with black borders */
.menu-section {
  flex: 1;
  min-width: 0;
  max-width: 33.33%;
  line-height: 0;
}
.middle-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
/* First menu section */
.menu-section:first-child {
  padding-right: 10px;
}

/* Middle menu section */
.menu-section:nth-child(2) {
  padding: 0 10px;
}

/* Last menu section */
.menu-section:last-child {
  padding-left: 10px;
}

.menu-section img {
  width: 100%;
  height: auto;
  display: block;
  border: 5px solid black;
  border-bottom: none;
  box-sizing: border-box;
}

.call-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px 30px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.call-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.call-controls {
  position: fixed;
  bottom: 15vh;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.call-button, .hangup-button {
  min-width: 150px;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  border: none;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: #FF6B6B;
  color: white;
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
}

.call-button:hover, .hangup-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0,0,0,0.25);
  background: #FF5252;
}

.duration {
  text-align: center;
  margin-top: 10px;
  color: white;
  font-weight: bold;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(
    to bottom,
    #87CEEB 0%,
    #87CEEB 80%,
    #696969 80%,  /* Darker grey for road */
    #696969 100%
  );
}

/* Add road lines */
.road-lines {
  position: absolute;
  bottom: 10vh;
  left: 0;
  width: 100%;
  height: 4px; /* Slightly thicker */
  background: repeating-linear-gradient(
    90deg,
    rgba(255, 215, 0, 0.7), /* Semi-transparent yellow */
    rgba(255, 215, 0, 0.7) 50px,
    transparent 50px,
    transparent 100px
  );
  box-shadow: 0 0 10px rgba(0,0,0,0.2); /* Subtle shadow */
}

.road-lines:before {
  content: '';
  position: absolute;
  top: -40px;
  width: 100%;
  height: 2px;
  background: repeating-linear-gradient(
    90deg,
    #FFD700,
    #FFD700 50px,
    transparent 50px,
    transparent 100px
  );
}

/* Add Tesla animation styles */
.tesla-car {
  position: fixed;
  bottom: 8vh; /* Lower position to be on the road */
  left: -200px;
  width: 200px; /* Slightly smaller size */
  height: auto;
  z-index: 5; /* Make sure it's above the road but below menu */
  animation: driveTesla 4s linear forwards;
  transform: scaleX(1);
}

@keyframes driveTesla {
  0% {
    left: -200px;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    left: calc(100% + 200px);
    opacity: 0;
  }
}

/* Media queries for different screen sizes */
@media (max-width: 1200px) {
  .menu-board {
    width: 95%;
  }
}

@media (max-width: 768px) {
  .menu-board {
    transform: scale(0.6);
  }
}

@media (max-width: 480px) {
  .menu-board {
    transform: scale(0.5);
  }
}

/* Add menu stand */
.menu-stand {
  content: '';
  position: absolute;
  bottom: -150px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 150px;
  background-color: #333;
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
}

/* Cloud styles */
.clouds-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70vh;
  overflow: hidden;
  pointer-events: none;
}

.cloud {
  position: absolute;
  background: white;
  border-radius: 50px;
  opacity: 0.8;
}

.cloud-1 {
  width: 200px;
  height: 60px;
  top: 20%;
  animation: floatCloud1 30s linear infinite;
}

.cloud-2 {
  width: 150px;
  height: 45px;
  top: 40%;
  animation: floatCloud2 25s linear infinite;
}

.cloud-3 {
  width: 180px;
  height: 55px;
  top: 10%;
  animation: floatCloud3 35s linear infinite;
}

.cloud-4 {
  width: 220px;
  height: 65px;
  top: 30%;
  animation: floatCloud1 40s linear infinite;
}

.cloud-5 {
  width: 160px;
  height: 50px;
  top: 15%;
  animation: floatCloud2 28s linear infinite;
}

.cloud-6 {
  width: 190px;
  height: 58px;
  top: 45%;
  animation: floatCloud3 33s linear infinite;
}

/* Cloud pseudo-elements for the fluffy parts */
.cloud::before,
.cloud::after {
  content: '';
  position: absolute;
  background: white;
  border-radius: 50%;
}

.cloud::before {
  width: 50px;
  height: 50px;
  top: -20px;
  left: 35px;
}

.cloud::after {
  width: 70px;
  height: 70px;
  top: -30px;
  right: 35px;
}

/* Cloud animations */
@keyframes floatCloud1 {
  from {
    left: -200px;
    transform: translateZ(-10px);
  }
  to {
    left: 100%;
    transform: translateZ(-10px);
  }
}

@keyframes floatCloud2 {
  from {
    right: -150px;
    transform: translateZ(-20px);
  }
  to {
    right: 100%;
    transform: translateZ(-20px);
  }
}

@keyframes floatCloud3 {
  from {
    left: -180px;
    transform: translateZ(-30px);
  }
  to {
    left: 100%;
    transform: translateZ(-30px);
  }
}

/* Drive-thru sign */
.drive-thru-sign {
  position: absolute;
  right: 50px;
  bottom: 22vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-circle {
  width: 120px;
  height: 120px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -10px;
  z-index: 2;
  overflow: hidden;
}

.sign-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.sign-rectangle {
  width: 180px;
  height: 60px;
  background: #f15c22;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Franklin Gothic', 'Arial Bold', sans-serif;
  font-weight: 900;
  font-size: 28px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  z-index: 1;
}

.sign-arrow {
  width: 120px;
  height: 50px;
  background: #f15c22;
  border-radius: 25px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  padding: 0;
}

.sign-pole {
  width: 12px;
  height: 120px;
  background: #4CAF50;
  margin-top: 10px;
}

.flex {
  display: flex;
}
.height-100 {
  height: 100%;
}
.border-black {
  border: 5px solid black;
  border-top-width: 0;
}
.border-bottm-none {
  border-bottom: none;
}


.input {
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.button:hover {
  background-color: #0056b3;
}
.password {
  margin-top: 20px;
  margin-bottom: 20px;
}
.phone-numbers {
  position: fixed;
  bottom: 30px;
  left: 20px;
}
.phone-numbers label, h4 {
  color: #fff;
  font-weight: bold;
}